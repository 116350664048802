import React, { useContext } from 'react'
import { useLocation } from '@reach/router'
import { Button } from 'suf_storybook'
import _ from 'lodash'
import Cookies from 'js-cookie'

import { loadMarketoForm } from '../lib'
import { OverlayContext } from './OverlayContext'

type ctaProps = {
  label: string
  target: string
  href: Object
  articlemarketoFormId?: string
  pdfUrl?: string
}

export const CTAButton = (ctaContent: any) => {
  const { content } = ctaContent
  const { setMarketoFormId, toggleOverlay } = useContext(OverlayContext)
  const location = useLocation()
  const accessReport =
    Cookies.get('sFWebAccessReportValidation') === 'true' ||
    (location.search.includes('sFWebAccessReportValidation=true') &&
      Cookies.get('mktoData'))

  return (
    <>
      {content && (
        <div>
          {content.map(
            ({ href, label, articlemarketoFormId, pdfUrl }: ctaProps) => {
              const link = _.get(href, 'href', '')
              if (articlemarketoFormId) {
                const handleClick = () => {
                  loadMarketoForm(
                    articlemarketoFormId,
                    toggleOverlay,
                    setMarketoFormId
                  )
                }
                return (
                  <div
                    className="button-wrapper button-wrapper--1"
                    key={label + articlemarketoFormId}
                  >
                    {accessReport && pdfUrl ? (
                      <div className="button button--1" role="presentation">
                        <a target="_blank" rel="noreferrer" href={pdfUrl}>
                          DOWNLOAD REPORT
                        </a>
                      </div>
                    ) : (
                      <div
                        className="button button--1"
                        role="presentation"
                        onClick={handleClick}
                        onKeyUp={handleClick}
                      >
                        {label}
                      </div>
                    )}
                  </div>
                )
              }
              return (
                <div
                  className="button__wrapper button__wrapper--1 report-viewer__button"
                  key={label + articlemarketoFormId}
                >
                  <Button
                    title={label}
                    className="button button--1"
                    link={link}
                  />
                </div>
              )
            }
          )}
        </div>
      )}
    </>
  )
}
export default CTAButton
