import React from 'react'
import loadable from '@loadable/component'
import uniqid from 'uniqid'
import { ArticleVideo } from 'suf_storybook'
import { CTAButton } from './CTAButton'

const IconTeaser = loadable(() => import('./iconTeaserSection'))
const InfoBlockMarkdownEditor = loadable(
  () => import('./infoBlockMarkdownSection')
)
const InfoBlockRichTextEditor = loadable(
  () => import('./infoBlockRichtextSection')
)
const KeyPoint = loadable(() => import('./keyPointSection'))
const PageTeaser = loadable(() => import('./pageTeaserSection'))

const VideoEmbed = ({ content }: { content: { embedId: string }[] }) => {
  if (!content[0]) return null
  const { embedId } = content[0]
  return (
    <ArticleVideo
      accountId="1771339158001"
      playerId="default"
      videoId={embedId}
    />
  )
}

const supported = {
  IconTeaser,
  InfoBlockMarkdownEditor,
  InfoBlockRichTextEditor,
  KeyPoint,
  Link: CTAButton,
  MarketoLink: CTAButton,
  PageTeaser,
  VideoEmbed
}

export type Props = {
  sections: any
}

export default ({ sections }: Props) => {
  return (
    <>
      {sections.map((section: any) => {
        const contentType = section.__typename.replace('Contentful', '')
        const Component = supported[contentType]
        return Component ? (
          <Component key={uniqid()} content={[section]} hideMoreSection />
        ) : null
      })}
    </>
  )
}
