import React from 'react'
import { graphql } from 'gatsby'
import { head } from 'lodash'

import '../../static/_css/video-js-control-bar-override.css'
import '../../static/_css/insights-listing-hero.css'

import { Social, RatingHeader, dateToTimestampMidnightET } from 'suf_storybook'

import ArticleSection from '../components/articleSection'
import Main from '../components/main'
import SEO from '../components/seo'

export default ({
  data: {
    contentfulArticlePage,
    site: {
      siteMetadata: { siteUrl }
    }
  },
  pageContext: { fullSlug }
}) => {
  const {
    articleType,
    dateTime,
    noIndex,
    sections: rawSections = [],
    showSocialIcons,
    title,
    __typename
  } = contentfulArticlePage!

  const sections = rawSections || []

  const { subtitle } = head(articleType)

  const RatingHeaderProps = {
    dateTime: false,
    publishedDate: dateToTimestampMidnightET(dateTime),
    reportType: subtitle,
    subtitle,
    title
  }

  return (
    <Main isInsights headerInverted={false}>
      <SEO
        title={title}
        description="article"
        noIndex={noIndex}
        type={__typename}
        keywords={[`Article`, title]}
      />
      <section className="section section--spacing-below">
        <article className="article access__article">
          <div className="wrapper--1">
            <header className="header--article">
              <div className="page-layout page-layout--2__left-main-right">
                <div className="column__left" />
                <div className="column__main">
                  <RatingHeader {...RatingHeaderProps} />
                </div>
              </div>
            </header>
            <div className="page-layout page-layout--2__left-main-right">
              <div className="column__left ta--2">
                {showSocialIcons ? (
                  <Social
                    copyText=""
                    root={siteUrl}
                    title={title}
                    url={fullSlug}
                    report
                  />
                ) : null}
              </div>
              <div className="column__main">
                <ArticleSection sections={sections} />
              </div>
            </div>
          </div>
        </article>
      </section>
    </Main>
  )
}

// TODO: do we need region, sector, etc tagging?
export const query = graphql`
  query ($slug: String) {
    contentfulArticlePage(slug: { eq: $slug }) {
      __typename
      aboveRelatedResearch {
        __typename
        ... on Node {
          ...ContentfulIconTeaserFragment
          ...ContentfulInfoBlockMarkdownEditorFragment
          ...ContentfulInfoBlockRichTextEditorFragment
          ... on ContentfulLink {
            id
            href {
              href
            }
            label
            target
          }
          ... on ContentfulMarketoLink {
            id
            label
            articlemarketoFormId: marketoFormId
          }
        }
      }
      articleType {
        subtitle
      }
      belowRelatedResearch {
        __typename
        ... on Node {
          ...ContentfulIconTeaserFragment
          ...ContentfulInfoBlockMarkdownEditorFragment
          ...ContentfulInfoBlockRichTextEditorFragment
          ... on ContentfulLink {
            id
            href {
              href
            }
            label
            target
          }
          ... on ContentfulMarketoLink {
            id
            articlemarketoFormId: marketoFormId
            label
          }
        }
      }
      countries {
        slug
        title
      }
      dateTime
      displayRelatedContent
      languages {
        title
        slug
      }
      internalNoIndex
      noIndex
      regions {
        slug
        title
      }
      sectors {
        slug
        title
      }
      sections {
        __typename
        ... on Node {
          ...ContentfulInfoBlockMarkdownEditorFragment
          ...ContentfulInfoBlockRichTextEditorFragment
          ...ContentfulKeyPointFragment
          ...ContentfulPageTeaserFragment
          ...ContentfulVideoEmbedFragment
          ... on ContentfulLink {
            id
            href {
              href
            }
            target
            label
          }
          ... on ContentfulMarketoLink {
            id
            articlemarketoFormId: marketoFormId
            label
            pdfUrl
          }
        }
      }
      showSocialIcons
      slug
      title
      topics {
        slug
        title
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
